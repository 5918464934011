import { render, staticRenderFns } from "./UnidadLayout.vue?vue&type=template&id=733c761e&scoped=true&"
import script from "./UnidadLayout.vue?vue&type=script&lang=js&"
export * from "./UnidadLayout.vue?vue&type=script&lang=js&"
import style0 from "./UnidadLayout.vue?vue&type=style&index=0&id=733c761e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "733c761e",
  null
  
)

export default component.exports